.quick-link {
    background: #eaf5f0;
    padding: 20px;
}

.quick-link--title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
}

.quick-link--links ul {
    margin: 0px;
    padding-left: 5px;
    list-style: none;
}

.quick-link--links ul li {
    margin-bottom: 10px;
}

.quick-link--links ul li a {
    color: #000;
}

.quick-link--links ul li:before {
    content: "";
    vertical-align: middle;
    background: #000;
    height: 5px;
    width: 5px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
}

.dropdown-insurance {
    padding: 0px;
    right: 15px;
}

@media screen and (max-width: 768px) {
    .quick-link {
        margin-top: 20px;
    }
}

@media screen and (max-width: 992px) {
    .quick-link {
        margin-top: 20px;
    }
}