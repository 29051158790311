#agentSearch .stOverflowAuto {
  overflow: visible;
}

#agentSearch .as-or {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}

#agentSearch .as-btn-container {
  margin-top: 10px;
}
#agentSearch .information {
  margin-top: 7px;
  margin-bottom: 0px;
}
#agentSearch .dataTables_filter {
  display: none;
}
#agentSearch table.dataTable .dropdown-menu {
  font-size: 12px;
}
@media (min-width: 768px) {
  #agentSearch .as-or {
    display: block;
    padding-top: 60px;
  }
  #agentSearch .as-btn-container {
    margin-top: 10px;
  }
}

#agentSearch tr.child li:nth-last-child(2) span.dtr-data:nth-child(2) {
  white-space: pre-wrap;
}

#agentSearch .gwp-wouldSelect {
  margin-top: 0px;
  font-size: 12px;
}
#agentSearch .gwp-wouldSelect > .caret {
  margin-left: 0px;
}
#agentSearch .margintop20px {
  margin-top: 20px !important;
}