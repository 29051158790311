#searchSection2 .gwp-label {
    color: #337ab7 !important;
}
#middleNameCol .gwp-label {
    color: black  !important;
}
#govtIDCol .gwp-label {
    color: black  !important;
}
#genderCol .gwp-label {
    color: black  !important;
}
#ssnCol .gwp-label {
    color: black  !important;
}
#WarnMessage .warnInfo {
    color: #888
}
#test1 .heightAndWidth{
    height: 86px;
    width: 298px;    
}
#sel_ownerType .marginTop{
    margin-top: 15px;
}
     
@media (min-width: 1025px) {
    #searchSection .minHide{
        display: none;
    }
    #searchSection .maxHide{
        display: block;
    }
}    
@media (max-width: 768px) {
    #searchSection .minHide{
        display: block;
    }
    #searchSection .maxHide{
        display: none;
    }
} 
@media (min-width: 770px) and (max-width: 1024px) {
    #searchSection .maxHide{
        display: block;
        margin-left: -50px;
        margin-top: -35px;
    }
    #searchSection .minHide{
        display: none;
    }
}

