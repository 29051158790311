#beneficiary .deleteStyle{
    text-align: center;
}
#beneficiary .gwp-table,#beneficiary table{
	margin-top: 0!important;
}
#beneficiary .table-bordered>thead>tr>td,#beneficiary .table-bordered>tbody>tr>td{
	vertical-align: middle;
}
#beneficiary .panel-body .gwp-label{
    color:#337ab7!important;
    margin-top:10px;
}
#beneficiary .percentageWidth {
	min-width: 80px!important;
}
#beneficiary .totalTr td{
    border-left-width: 0 !important;
	border-right-width: 0 !important;
}