#annuityPayee .gwp-label + span {
	margin-left:40px;
}

#annuityPayee .disbursementMethod {
	margin-left:43px;
}

#annuityPayee .contractIcon {
	margin-bottom:-3px;
}

#annuityPayee .dl-horizontal dd {
    height:10px;
    margin-left:0;
}

#annuityPayee #annuityPayeeTable_length {
    display: none;
  }

  @media (max-width: 767px) {
    #annuityPayee .textAboveTable {
        margin-bottom:-12px;
    }
    #annuityPayee .textInTable {
        margin-bottom:12px;
    }
}