#enrollment .hide {
    display:none!important;
  }
#enrollment .show {
    display:block!important;
}
#enrollment .linkTextField{
  margin-top:12px!important;
}
#enrollment .indiCheckbox{
  margin-top:5px!important;
}
#enrollment .benefitOutLink{
  background-color:yellow!important;
}
#enrollment .pull-right {
  float: right !important;
  margin-top: 0px!important;
}
#enrollment .stepTitle {
	margin: 10px 0px;
    clear: both;
    background-color: #f5f5f5;
    clear: both;
    padding:10px 0px; 
     
}
@media (max-width: 400px){
  #enrollment .pull-right {
    float: none!important;
    margin-top: 0px!important;
  }
}