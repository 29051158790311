.divider {
    display: block;
    margin: 15px 0px;
    height: 1px;
    background: #ddd;
}

.row-item {
    margin: 15px 0px;
}

.d-flex {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}

.child-flex {
    display: flex;
    align-items: center;
}

.img-list {
    margin: 20px 0px;
}

.img-list hr {
	
    margin: 0px 0px;
}

.item-border .item {
    border: 1px solid #888;
    min-height: 160px;
}

.item img {
    cursor: pointer;
    max-width: 200px;
}

.item-border .item img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.item-title {
    margin-bottom: 5px;
    text-transform: capitalize;    
  	background-color: #DDD; /* For browsers that do not support gradients */
  	background-image: linear-gradient(to right, #DDD, white);
}

.category {
	margin-bottom: 50px;
}
