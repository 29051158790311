#beneficiaryViewInformation .gwp-label {
	color:#337ab7 !important;
}
#beneficiaryViewInformation .totalPercentage {
	color:black !important;
	font-size: 12px;
	font-weight: 700;
}

#beneficiaryViewInformation th>div>div>.gwp-label {
	color:black !important;
	margin-top: 0px
}

#beneficiaryViewInformation .checkBox1 {
	margin-top: 5px;
}

#beneficiaryViewInformation .gwp-inputAddOn {
	margin-top: 0px;
    margin-bottom: 0px;
}

#beneficiaryViewInformation .dataTables_info {
	display: none;
}

#beneficiaryViewInformation .table>tbody>tr>td {
	vertical-align: middle;
}

#beneficiaryViewInformation table>thead>tr>th {
	vertical-align:top;
}

#beneficiaryViewInformation .hiddenBolder {
	border: 0px;
}
#beneficiaryViewInformation .hideTD {
	border: 0px;
	display: none;
}
#beneficiaryViewInformation table.collapsed .hideTD {
	display: block;
}
#beneficiaryViewInformation .warnMessage {
	color: #a94442;
}
#beneficiaryViewInformation .gwp-input{
	margin-top: 0px;
	margin-bottom: 5px;
}
#beneficiaryViewInformation .noBeneficiaryWarning{
	color: rgb(136, 136, 136);
}

/* @media (max-width: 415px) and (min-width: 376px) {
    #beneficiaryViewInformation .input-group-addon{
        padding: 0px;
    }
}  */
@media (min-width:770px ) {
	#beneficiaryViewInformation .helpBanner {
		float: right;
	}
}
@media (max-width: 769px) {
    #beneficiaryViewInformation .helpBanner {
        float: left !important;
		margin-bottom: 20px !important;
		margin-top: 20px !important;
    }	
} 

@media (max-width:535px ) {
	#beneficiaryViewInformation .hideDEC {
		display: none;
	}
	#beneficiaryViewInformation .showDEC {
		display: block;
		color: #888;
	}
}
@media (min-width:536px ) {
	#beneficiaryViewInformation .hideDEC {
		display: block;
	}
	#beneficiaryViewInformation .showDEC {
		display: none;
	}
}

#beneficiaryViewInformation .percentageWidth{
	min-width: 90px!important;
}

#beneficiaryViewInformation .dl-horizontal dt{
	text-align: left !important;
}
@media (min-width: 768px) {
	#beneficiaryViewInformation .gwp-page-header {
		width: 152%;
	  }
	#beneficiaryViewInformation .help-banneer {
		float: right;
	  }
	}