/*inforcebusiness.css*/
#inforceBusiness .minimize {
  height: 0px;
  width: 0px;
}
#inforceBusiness .gwp-icon-view-all {
  margin-bottom: -3px;
}
#inforceBusiness .mobileViewPort {
  margin-top: 5px;
}
#inforceBusiness .collapsed .gwp-details-control:before {
  left: 9px !important;
}
#inforceBusiness .collapsed .gwp-details-control {
  width: 1px !important;
}
#inforceBusiness #INB_gridTable .panel {
  margin-top: 10px;
}
#inforceBusiness #agentOption {
  display: none;
}
#inforceBusiness .numberInTable {
  text-align: right;
}
#inforceBusiness .numberInTableMobile {
  text-align: center;
}
#inforceBusiness .modal-body {
  max-height: 400px;
  overflow: auto;
}
#inforceBusiness .modalInfo {
  margin-left: 13px;
}
#inforceBusiness .myagent_btn_container {
  margin-top: 25px;
}
#inforceBusiness #showAll_my_agent_btn {
  margin-top: 5px;
  display: inline-block;
}
#inforceBusiness #agentSelectDiv {
  margin-top: 10px;
}

#inforceBusiness #multipleAgentsTable_length {
  display: none;
}

#inforceBusiness .retriveBtn {
  margin-top: 27px;
}

#inforceBusiness hr {
  margin: 10px 0px;
}
#inforceBusiness #drib input {
  font-size: inherit;
}
#inforceBusiness #myMultiDirReportDiv {
  margin-top: 10px;
}
#inforceBusiness .stOverflowAuto {
  overflow: initial;
}
#inforceBusiness .tooltip.bottom .tooltip-arrow.gwp-tooltip-arrow1 {
  border-bottom-color: #ddd !important;
}
#inforceBusiness .tooltip.bottom .tooltip-arrow.gwp-tooltip-arrow2 {
  border-bottom-color: #fff !important;
  margin-top: 1px;
}
#inforceBusiness .tooltip.bottom .tooltip-arrow:before {
  border-bottom-color: #ffffff !important;
  content: " ";
}
#inforceBusiness td .tooltip-inner {
  background-color: #ffffff !important;
  border: 1px solid #dddddd;
  color: #222222;
  text-align: left;
}
#inforceBusiness .panel-body div {
  line-height: 2em;
}

@media (min-width: 1025px) {
  #inforceBusiness .agentDropDown #myAgent{
    width: 260px;
  }

  #inforceBusiness .agentDropDown #mySelectedAgents{
    width: 260px;
  }

  #inforceBusiness .agentDropDown {
    width: 275px;
  }
  
}

#inforceBusiness dl dd {
  min-height: 17px;
}
#inforceBusiness .btn-container {
  margin-top: 10px !important;
}
#inforceBusiness .m-line {
  border-top: 3px solid #ddd;
  font-size: 0;
  height: 3px;
  margin-top: 10px;
}
#inforceBusiness .combobox-container {
  width: 270px;
}

@media (min-width: 768px) {
  #inforceBusiness #agentSelectDiv {
    margin-top: 33px;
  }
  #inforceBusiness .filter_myApp {
    margin-bottom: -15px;
  }
  #inforceBusiness .btn-container {
    margin-top: 25px !important;
  }
  #inforceBusiness .expandTemplate {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  #inforceBusiness table.dataTable.collapsed th.gwp-dt-detail-control,
  .gwp-portlet-container
    .dataTables_wrapper
    table.dataTable.collapsed
    td.gwp-dt-detail-control {
    height: 20px;
  }

#inforceBusiness .panelInTable {
    margin-top:10px;
}
}
