#paymentDetail .marginbottom10px {
    margin-bottom: 10px !important;
  }
  #paymentDetail .marginbottom5px {
    margin-bottom: 5px !important;
  }
#paymentDetail .marginbottom0px {
    margin-bottom: 0px !important;
  }
  #paymentDetail .gwp-dl {
    margin-bottom: 0px !important;
  }
  #paymentDetail .width130px {
    width: 130px !important;
  }
  #paymentDetail .gwp-icon-label_1 {
    font-weight: normal;    
  }
  #paymentDetail .gwp-icon-label {
    font-weight: 700;    
  }
  
  @media (min-width: 768px){	
    #paymentDetail  .gwp-page-header{
      width:152%;
   }
   #paymentDetail .dl-horizontal dt {
      width: 140px;
    }
    #paymentDetail .dl-horizontal dd {
      margin-left: 165px;
    }
    #paymentDetail .retrievemargintop {
      margin-top: 30px !important;
    }
 }
 @media (min-width: 1200px){	
  #paymentDetail .gwp-page-header{
    width:135%;
 }
 #paymentDetail .retrievemargintop {
  margin-top: 5px !important;
}

}
@media (max-width: 767px){	
#paymentDetail .retrievemargintop {
  margin-top: 10px !important;
}
}

  #paymentDetail .margintop10px {
    margin-top: 10px !important;
  }

  #paymentDetail .margintop5px {
    margin-top: 5px !important;
  }

  #paymentDetail .margintop0px {
    margin-top: 0px !important;
  }
  
  #paymentDetail .paddingleft0px {
    padding-left: 0px !important;
  }
