#changePaymentMethod .mi-list-content {
  border-bottom: 1px solid #e9eae7;
  border-right: 1px solid #e9eae7;
  border-top: 1px solid #e9eae7;
  border-left: 1px solid #e9eae7;
  margin: 0;
  border-left-color: #ccc;
}
#changePaymentMethod .gwp-label-dp {
  margin-top: 10px;
}
#changePaymentMethod .hmi-divider {
  height: 40px;
  vertical-align: middle;
  padding-left: 15px;
  padding-top: 6px;
  font-weight: bold;
}

#changePaymentMethod .viewWallet {
  font-size: 12px;
}

#changePaymentMethod .iconWalletSpace {
  margin-right: 4px;
  margin-left: 6px;
}

#changePaymentMethod .align-bottom {
  vertical-align: bottom;
}

#changePaymentMethod .mi-list-row {
  border-left-width: 15px;
  border-left-style: solid;
  border-left-color: #c9e7ac;
  border-bottom: 1px solid #e9eae7;
  border-right: 1px solid #e9eae7;
  margin: 0;
  border-left-color: #ccc;
}

#changePaymentMethod .mi-list-row-title {
  color: #39afe6;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
  padding-top: 10px;
}

#changePaymentMethod .helpBanner {
  /* margin-right: 13px; */
  float: right;
}

#changePaymentMethod .gwp-page-header {
  border-bottom: 1px solid #ddd;
  margin: 20px 0 10px 0;
  font-size: 0;
  padding-bottom: 1px !important;
}
#changePaymentMethod .daterange {
  margin-left: 3px;
  color: #888;
}
#changePaymentMethod .expdateicon {
  font-size: 12px;
  color: #888;
}

#changePaymentMethod .gwp-label-required {
  font-size: 12px;
  font-weight: 700;
  margin: 5px 10px 5px 0;
  color: #337ab7;
}
#changePaymentMethod .boldfont {
  font-weight: bold;
}

#changePaymentMethod .gwp-icon-label {
  font-size: 12px;
}

#changePaymentMethod .modal-footer {
  padding: 15px;
  text-align: left;
}

#changePaymentMethod .modal-message {
  margin-top: 0px;
}
#changePaymentMethod .messageIcon {
  margin-right: 10px;
}
#changePaymentMethod .payout-information-body {
  margin-top: 0px;
  margin-bottom: 0px;
}
#changePaymentMethod .dl-horizontal dt {
  text-align: left;
  padding-left: 0px;
  padding-top: 0px;
}
#changePaymentMethod .dl-horizontal dd {
  padding-top: 0px;
  padding-left: 0px;
}

#changePaymentMethod .currency {
  margin-bottom: 8px;
}
#changePaymentMethod .fireFoxPlace {
  margin-top: -3px;
}
#changePaymentMethod .inforce-detail-group1 {
  margin-top: 5px;
}
#changePaymentMethod .gwp-label.normal {
  font-weight: normal;
  margin: 0;
}
#changePaymentMethod .addCreditCard {
  padding-left: 0px;
}
#changePaymentMethod .addCreditCard > .gwp-icon-label {
  font-weight: normal;
}
#changePaymentMethod #updatePersonalInfo + span {
  font-weight: normal;
}
#changePaymentMethod #submitControl {
  background: repeat scroll 0% 0% rgb(242, 242, 242);
}
#changePaymentMethod .nextPaymentDateRange {
  margin-top: 5px;
}
@media (max-width: 768px) {
  #changePaymentMethod .inforce-detail-group1 {
    margin-top: 5px;
  }
}
@media (max-width: 430px) {
  #changePaymentMethod .helpBanner {
    float: left;
  }

  #changePaymentMethod .panelHeadSize {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  #changePaymentMethod .gwp-page-header {
    width: 152%;
  }
  #changePaymentMethod .help-banneer {
    float: right;
  }
}
