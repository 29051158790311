/* SystemConfiguration.css */


#systemConfiguration .sysconf-inputAddon {
	max-width: 350px;
	display: inline;
}
#systemConfiguration .form-group {
	margin-bottom:  10px !important;
}

#systemConfiguration .mailSubject, .mailBody{
	font-size: 12px !important;
}
#systemConfiguration .input-group-addon {
	font-size: 12px;
	width: 81px;
}
#systemConfiguration .addLabel {
	margin-top: 20px;
}
#systemConfiguration .exportSubject {
	font-size: 15px !important;
}
#systemConfiguration .exportSelectFrequency {
	width: 260px;
	margin-left: -10px;
}
#systemConfiguration .exportSelect {
	width: 130px;
	margin-left: -10px;
	margin-right: 10px;
}
#systemConfiguration .exportTimeTitle {
	margin-top: 10px;
}
#systemConfiguration .sysconf-inputAddon input {
	width: 180px;
	display: inline;
}
#systemConfiguration .exportButton {
	margin-top: -5px;
}
@media (min-width: 768px) and (max-width: 992px) {
	#systemConfiguration .exportSelectFrequency {
		width: 200px;
		margin-left: -10px;
	}
	#systemConfiguration .exportSelect {
		width: 100px;
		margin-left: -10px;
		margin-right: 10px;
	}
	#systemConfiguration .sysconf-inputAddon input {
		width: 120px;
		display: inline;
	}
}
@media (max-width: 767px){
	#systemConfiguration .exportSelectFrequency {
		width: 150px;
		margin-left: -10px;
	}
	#systemConfiguration .exportSelect {
		width: 150px;
		margin-left: -10px;
		margin-right: 10px;
	}
	#systemConfiguration .sysconf-inputAddon input {
		width: 60px;
		display: inline;
	}
	#systemConfiguration .exportButton {
		margin-top: 10px;
	}
}
/* 统一布局*/