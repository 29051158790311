#insurance_comp .mi-divider {
	
    height: 40px;    
    vertical-align: middle;
    padding-left: 15px;
    padding-top: 6px;
	font-weight:bold;

}

#insurance_comp .mi-list-row{
	border-left-width: 15px;
	border-left-style: solid;
	border-left-color: #c9e7ac;
	border-bottom: 1px solid #e9eae7;
	border-right: 1px solid #e9eae7;
	margin:0;   
	border-left-color:#ccc;
}

#insurance_comp .mi-list-row-title{
	color: #39afe6;
	font-size: 14px;
	margin-bottom: 10px;
	font-weight:bold;
	padding-top:10px;
}

#insurance_comp .dl-horizontal dt{
	text-align:left;
	padding-left:15px;
	padding-top:10px;
}

#insurance_comp .dl-horizontal dd  {
	padding-top: 10px;
	padding-left:15px;
}

#insurance_comp .dropdown {
	position: relative;
}

#insurance_comp .gwp-icon-validate {
    background-size: 13px;
    float: left;
    height: 60px;
    width: 13px;
    margin-bottom: -1px;
    padding: 10px;
}

#insurance_comp .changSecondaryAddressee {
    margin-left: -9px;
}

#balance_summary_result tbody tr td.insurance-column{
	padding-right: 8px !important;	
}

#balance_summary_result thead tr th.insurance-column{
	text-align: left;
}

@media (min-width:767px) and (max-width:768px){
    #insurance_comp .btn-group>.btn:first-child{
		margin-left:-30px;
	}
	
	#insurance_comp ul.mi-menu {
		margin-left:-55px;
	}
	
	#insurance_comp .dl-horizontal dd{
		padding-left:0px;
		margin-left:0px;
	}
	
	#insurance_comp .dl-horizontal-secaddr dd{
		padding-left: 15px;
		margin-left: 180px;
	}
	#insurance_comp .dl-horizontal-secaddr dt{
		width:160px;
	} 
}

#insurance_comp #successUpdateInfo .msgTitle {
	margin-bottom: 5px;

}
#insurance_comp .gwp-icon-validate {
	background-size: 13px;
    float: left;
    height: 60px;
	width: 13px;
	margin-bottom: -1px;
    padding: 10px;
}

#pendingTransaction .pfoeclass1{
	border:1px solid #BFBFBF;
	padding:12px;
	margin-bottom: 10px;
}

#pendingTransaction .tableGray{
	background-color: gray;
}

#openEnrollment .pfoeclass1{
	border:1px solid #BFBFBF;
	padding:12px;
	margin-bottom: 10px;
}
#openEnrollment .pfoeclass2{
	margin-top:15px;
}

#openEnrollment .enrollment-icon{
	position:relative;
}

#openEnrollment .enrollment-icon > img{
	position:absolute;
	top:-30px;
}

@media (max-width: 768px){
	#openEnrollment .enrollment-icon{
		position:relative;
	}	

	#openEnrollment .enrollment-icon > img{
		position:relative;
		top:0;
	}
}

@media (min-width: 768px)and (max-width:1023px) {
	#insurance_comp .secondaryAddress {
	  margin-left: 159px;
	}
  }