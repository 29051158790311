#enrollment .panel {
  margin-top: 10px;
}
#enrollment .panel .gwp-btn-container.gwp-float-right {
  margin-top: -4px;
}
#enrollment .checkboxStyle {
  margin-top: 5px;
}

#enrollment .eftMsgStyle {
	margin-top: 10px; 
}

#enrollment .salaryStyle1{
	float: left!important;
	width: 50%;
}
#enrollment .salaryStyle2{
	float: left!important;
	width: 2%;
	margin-top: 38px!important;
	margin-left: 5px;
}
#enrollment .salaryStyle3{
	float: left!important;
	width: 46%;
	margin-top: 10px!important;
}
@media (max-width: 769px){
	#enrollment .salaryStyle1{
    	float: none!important;
		width: 100%;
	}
	#enrollment .salaryStyle2{
    	width: 100%;
    	margin-top: 10px!important;
	}
	#enrollment .salaryStyle3{
    	float: none!important;
		width: 100%;
    	margin-top: 0px!important;
	}
}
@media (max-width: 400px){
	#enrollment .panel .gwp-btn-container.gwp-float-right {
		float: none!important;
    	margin-top: 0px!important;
	}
  }

#enrollment .gwp-btn-plus{
	margin-top: 10px!important;
}

#enrollment .beneficiaryChkBox{
    margin-top:5px!important;
}

#enrollment .confirmationWarnsubmit {
	color:red;
}