/* billingpaymentsummary.css */
#billingPaymentSummary .float-right {
  float: right;
}
#billingPaymentSummary .agentList {
  margin: 5px 0 5px 5px;
}
#billingPaymentSummary .summarydd {
  padding-right: 5px !important;
  margin-top: 5px !important;
  float: right;
}
#billingPaymentSummary .adjusttoBottom {
  margin-bottom: 0px !important;
}
@media (max-width: 1000px) {
  #billingPaymentSummary .gwp-bp-float-right {
    float: left !important;
  }
  #billingPaymentSummary .summarydd {
    padding-right: 5px !important;
    margin-top: 5px !important;
    float: left;
  }  
}
@media (max-width: 767px) {
  #billingPaymentSummary .retrieveBtnDIV {
    margin-top: 5px;
    margin-left: 1px;
  }
  #billingPaymentSummary .summarydd {
    padding-right: 5px !important;
    margin-top: 5px !important;
    float: left;
  }
  #billingPaymentSummary .agentList>.dl-horizontal dt{
    width: 50px;
  }
  #billingPaymentSummary .agentList>.dl-horizontal dd{
    margin-left: 0px !important;
  }
  #billingPaymentSummary .summaryGridDiv2{
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  #billingPaymentSummary .retrieveBtnDIV {
    margin-top: 25px;
    margin-left: 148px;
  }
  #billingPaymentSummary .balanceDIV {
    display: inline-block;
    min-width: 100%;
    margin-top: 5px;
  }
  #billingPaymentSummary .agentList>.dl-horizontal dt{
     width: 50px;
  }
  #billingPaymentSummary .agentList>.dl-horizontal dd{
    margin-left: 70px;
  }
}
#billingPaymentSummary #allAgentsDialog .dl-horizontal>dt {
  color: #337ab7 !important;
  width: 20% !important;
}

@media (min-width: 993px) {
  #billingPaymentSummary .retrieveBtnDIV {
    margin-top: 25px;
    margin-left: 15px;
  }
}
#billingPaymentSummary .agentList>.dl-horizontal dt{
  width: 50px;
}
#billingPaymentSummary .agentList>.dl-horizontal dd{
  margin-left: 70px;
}
#billingPaymentSummary .dl-horizontal{
  margin-bottom:0px;
}
#billingPaymentSummary .dl-horizontal>dt .dl-horizontal>dd{
  width:auto;
}
#billingPaymentSummary .dl-horizontal>dt>title .dl-horizontal>dd>title{
  width:100px;
}
#billingPaymentSummary .dl-horizontal dt{
  color:black;
  margin: 0!important;
}

#billingPaymentSummary .gwp-bp-float-right {
  float: right;
}
#billingPaymentSummary .agentinfoDiv {
  margin: 5px 0 5px 5px;
}
#billingPaymentSummary .agentinfoDiv dl {
  margin-bottom: 5px;
}
#billingPaymentSummary .nomargin {
  margin: 0px !important;
}
#billingPaymentSummary .relateinfo dd {
  margin-left: 0;
  min-height: 17px;
}

#billingPaymentSummary .marginbottom5px {
  margin-bottom: 5px !important;
}
#billingPaymentSummary .noheigt {
  line-height: 0px !important;
}
#billingPaymentSummary .shortdd {
  line-height: 1.42857143px !important;
  min-height: 1.42857143px !important;
}
#billingPaymentSummary .margin-left-2px {
  margin-left: 2px !important;
}
#billingPaymentSummary .printButton {
	border: 0; 
	background-color: transparent; 
	outline: none;
	color:#009ace;
	font-weight: bold;
	height: 31px;
	cursor: pointer;
	font-size:12px!important;
}
#billingPaymentSummary .agentPanelRemoveBoder{   
  border: none;
  box-shadow: none;
}