.sufix-container {
    text-align: center;
}
.sufix-container span {
    padding: 0px 10px!important;
}
.reports {
    text-align: center;
}
.reports span {
    padding: 0px 7px;
}

#accessPermission .DLfontSize1{
    margin: 10px 0 -2px;
}

#accessPermission .DLMargin{
    margin-top: 0px;
    margin-bottom: -15px !important;
}

@media (max-width: 768px) { 
	#accessPermission .DLMargin {
		margin-top: 0px;
        margin-bottom: 0px !important;
	}
}