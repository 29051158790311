#paymentResult .messageBanner {
  margin-bottom: 0px !important;
}

#paymentResult .messageIcon {
  margin-right: 10px;
}

#paymentResult .printPlace {
  float: right;
  margin-top: -3px;
}

#paymentResult .gwp-link-btn {
  background-color: #dff0d8;
}
#paymentResult .gwp-float-right {
  display: inline-block;
}
#paymentResult .payout-information-body {
  margin-left: 0px;
}
#paymentResult .gwp-btn-container {
  margin-top: 0px;
  display: inline-block;
}
#paymentResult .panel-default {
  border-color: white;
  box-shadow: 0px 0px 0px white;
}
#paymentResult {
  min-width: 270px;
}
#paymentResult .panel-body {
  padding: 15px 0 15px 0;
}
#paymentResult .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
#paymentResult .currencyInfo {
  margin: 12px 0;
}

#paymentResult .gwp-page-title {
  font-weight: 600;
  font-size: 20px;
  margin: 5px 0;
}

#paymentResult .modal-footer2 {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
#paymentResult .hrstyle {
  margin-top: 1px;
  margin-bottom: 5px;
}
#paymentResult .failurerow {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 15px;
}
#paymentResult .timeout {
  margin-top: 15px;
}
