#manageFaqQuestion ul {
  padding: 10px;
  width: 90%;
}
#manageFaqQuestion ul > li {
  padding: 5px;
  width: 50%;
  display: inline-table;
}

#manageFaqQuestion ul > li > a {
  margin-left: 10px;
}
#manageFaqQuestion ul > li > img {
  margin-top: -2px;
}
#manageFaqQuestion ul > li > input[type="checkbox"] {
  margin-right: 10px;
}
#manageFaqQuestion ul > li > label {
  margin: 0px;
}
#manageFaqQuestion #createNewQuestion input[name="question"] {
  max-width: 100%;
}
#manageFaqQuestion #createNewQuestion label,
.gwp-label,
.gwp-label-required {
  margin: 5px 0 5px 0;
}
#manageFaqQuestion #createNewQuestion ul > li {
  width: 50%;
  display: inline-table;
}
#manageFaqQuestion #createNewQuestion ul > li > label {
  display: inline;
}
#manageFaqQuestion #createNewQuestion ul > li > div > label {
  margin: 0px;
}
#manageFaqQuestion .faqQuestionSubTitle {
  font-size: 20px;
  font-weight: 300;
  margin: 5px 0;
}
#manageFaqQuestion .categoreis .gwp-label {
  margin-top: 10px;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  #manageFaqQuestion > ul > li {
    width: 100%;
  }
  #manageFaqQuestion #createNewQuestion ul > li {
    width: 100%;
    display: inline-table;
  }
}
