#forgotuserid .gwp-title-message{
    font-size: 16px;
	font-weight: bold;
}
#forgotuserid .line{    
	margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 10px solid #eee;
}
#forgotuserid .radioMethod{
	margin-top: 0px;
}
#forgotuserid .radText{
	font-style:italic;
	margin-left: 10px;
}
#forgotuserid .radText a{
	text-decoration: underline !important;
}
#forgotuserid .forgotuid-warn-icon {
	background-size: 12px;
	height: 12px;
	width: 12px;
	margin-bottom: -1px;
	
}


#forgotuserid #validErrorDlg .gwp-btn-container {
	margin-top:0px!important;
}
#forgotuserid .wideTop{
	margin-top:10px;
}
#forgotuserid .widebuttom{
	margin-bottom:10px;
}
#validErrorDlg .gwp-icon-xs{
    background-size:10px;
    height:10px;
    width:10px;
}
#sendEmailDlg .gwp-icon-xs{
    background-size:10px;
    height:10px;
    width:10px;
}
#sendErrorDlg .gwp-icon-xs{
    background-size:10px;
    height:10px;
    width:10px;
}
@media (min-width: 768px) {
	#validErrorDlg .modal-content{
		width:598px!important;
	}
	#validErrorDlg .modal-dialog{
		width:600px!important;
	}
	#sendEmailDlg .modal-content{
		width:598px!important;
	}
	#sendEmailDlg .modal-dialog{
		width:600px!important;
	}
	#sendErrorDlg .modal-content{
		width:598px!important;
	}
	#sendErrorDlg .modal-dialog{
		width:600px!important;
	}			
}