#newBusinessApplications .multiAgents{
    margin-top: 25px !important;    
}

#newBusinessApplications .tooltip.bottom .tooltip-arrow .gwp-tooltip-arrow1 {
    border-bottom-color: #ddd !important;
}
#newBusinessApplications .tooltip.bottom .tooltip-arrow .gwp-tooltip-arrow2 {
    border-bottom-color: #fff !important;
    margin-top: 1px;
}
#newBusinessApplications .tooltip.bottom .tooltip-arrow:before {
    border-bottom-color: #ffffff !important;
    content: " ";
}

#newBusinessApplications td .tooltip-inner {
    background-color: #ffffff !important;
    border: 1px solid #dddddd;
    color: #222222;
    text-align: left;
}
#newBusinessApplications .showAgentInfo{
    display:block;
}
#newBusinessApplications .dtr-data > .showAgentInfo{
    display:inline;
}

@media (max-width: 820px){
	#newBusinessApplications .style{			
			width: 171 px !important;
		}		
	#newBusinessApplications .detailTable1{
		width: 55%;
	}
	#newBusinessApplications .detailTable2{
		width: 52%;
	}
    #newBusinessApplications .tooltip.bottom{
		left: 0px  !important;
	}
} 
@media (min-width: 1025px){
    #newBusinessApplications .selectControl #agentNumber{
        width: 260px;
    }
    #newBusinessApplications .selectControl #selectedMyAgents{
        width: 260px;
    }
}

#newBusinessApplications .multiDrAgt {
    margin-top: 35px;
}
#newBusinessApplications .multiMyAgt {
    margin-top: 35px;
}