#registration .reginnertitle,
#userRegistration .reginnertitle,
#adminRegistration .reginnertitle {
  color: #4abece;
  font-weight: bold;
  font-size: 14px;
}

#registration .gwp-page-message,
#userRegistration .gwp-page-message,
#adminRegistration .gwp-page-message {
  margin-top: 10px;
}
#registration .information.gwp-information,
#userRegistration .information.gwp-information,
#adminRegistration .information.gwp-information {
  margin-top: 10px;
}

#registration .secImgs li.checked,
#userRegistration .secImgs li.checked,
#adminRegistration .secImgs li.checked {
  padding: 0px;
  border: 4px solid #73a652;
  outline-style: none;
}

#registration ul.secImgs,
#userRegistration ul.secImgs,
#adminRegistration ul.secImgs {
  max-width: 264px;
  padding-left: 0px;
}

#registration ul.secImgs li.unchecked,
#userRegistration ul.secImgs li.unchecked,
#adminRegistration ul.secImgs li.unchecked {
  width: 85px;
  height: 80px;
  border: 1px solid #111;
  position: relative;
  margin-top: 5px;
}
#registration colAlign,
#userRegistration colAlign,
#adminRegistration colAligns {
  padding-left: 0px;
}
#registration ul,
#userRegistration ul,
#adminRegistration ul {
  margin-top: 5px;
  padding-left: 20px;
}
#registration li,
#userRegistration li,
#adminRegistration li {
  list-style-type: disc;
}
#registration .hide,
#userRegistration .hide,
#adminRegistration .hide{
  visibility: hidden;
}
#registration .marginNationalInfo,
#userRegistration .marginNationalInfo,
#adminRegistration .marginNationalInfo{
  margin-top:20px!important;
}