#transactionList .nobold {
	font-weight: normal !important;
}


#transactionList .even.disabled,
#transactionList .odd.disabled,
#transactionList .odd.disabled:hover,
#transactionList .even.disabled:hover
 {
  color:rgb(51,51,51)!important;
}