@media (min-width:1024px ) {
	#personalInformation .helpBanner {
		float: right;
	}
    #personalInformation .maxHide{
        display: block;
        float: right;
        margin-left: -50px;
        margin-top: -35px;
    }
    #personalInformation .minHide{
        display: none;
    }
}
@media (max-width: 1023px) {
    #personalInformation .helpBanner {
        float: left !important;
		margin-bottom: 20px !important;
		margin-top: 20px !important;
    }	
    #personalInformation .minHide{
        display: block;
    }
    #personalInformation .maxHide{
        display: none;
    }
} 
@media (max-width: 415px) {
    #personalInformation .emailControl{
        margin-top: 10px !important;
    }
}
