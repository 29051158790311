#dashboard .noBorder {
    border: none;
  }
  #dashboard .displayNone {
    display: none;
  }
  #dashboard .noBackground {
    background-color: unset;
  }
  #dashboard .panel-heading {
    background-color: unset;
    border: none;
  }
  #dashboard .fontBlue {
    color: #009ace;
  }
  #dashboard .fontGreen {
    color: #008000;
  }
  #dashboard .gwp-page-title {
    color: #008000;
  }
  