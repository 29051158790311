#cognosReport .noBorder {
  border: none;
}
#cognosReport .displayNone {
  display: none;
}
#cognosReport .noBackground {
  background-color: unset;
}
#cognosReport .panel-heading {
  background-color: unset;
  border: none;
}
#cognosReport .fontBlue {
  color: #009ace;
}
#cognosReport .fontGreen {
  color: #008000;
}
#cognosReport .gwp-page-title {
  color: #008000;
}
