#claimDetailComp .currencyRow {
    margin-bottom: 10px;
}

#claimDetailComp .benefitLimits {
    font-weight: bold;
    color: #7F7F7F;
    font-size: 12px;
    margin-left: 40px;
}

#claimDetailComp .dl-horizontal dt {
    width: 110px;
}

#claimDetailComp .dl-horizontal dd {
    margin-left: 125px;
}

@media (max-width: 767px){
    #claimDetailComp .benefitLimits {
        font-weight: bold;
        color: #7F7F7F;
        font-size: 12px;
        margin-left: -8px;
    }
}

#claimDetailComp .multiplepayeetitle{
    color: #337ab7;
    font-weight: 700;
}

#claimDetailComp .gwp-table{
    margin-top: 0px;
}

#claimDetailComp .table>tbody>tr>td {
    padding: 8px;
    line-height: 1.4285714;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

#claimDetailComp .pd-header-panel {
    border-width: 0 0 1px 0;
}

#claimDetailComp .pd-header-panel>.panel-body {
    padding: 15px 0 0 0;
}

#claimDetailComp .pd-header-panel>.panel-body>.row {
    line-height: 40px;
}

#claimDetailComp .bl-border-panel {
    border-width: 1px 1px 0 1px;
}

#claimDetailComp .borderless {
    border: none;
    box-shadow: none;
}

#claimDetailComp .coverage-panel-body .dataTable {
    width: 100%;
    border: none !important;
}

#claimDetailComp .coverage-panel-body table th {
    border: none !important;
}

#claimDetailComp .coverage-panel-body table td {
    border: none !important;
}

#claimDetailComp .coverage-panel-header {
    font-weight: 600;
}

#multiplePayeesDialog .dl-horizontal.gwp-dl > dt {
    text-align: right;
}

#claimDetailComp .coverage-panel-body-title {
    background: #e0f0d0;
    line-height: 30px;
}

#claimDetailComp .coverage-panel-body-content {
    line-height: 30px;
}

#claimDetailComp .coverage-panel-body-content2 {
    line-height: 30px;
    background: #edebdb;
}

#claimDetailComp .pd-fontbold {
    font-weight: bold;
}

#claimDetailComp .claim-title {
    font-weight: bold;
    font-size: 16px;
}

#claimDetailComp .extInfo {
    margin-left: 1px !important;
}

#claimDetailComp table.dataTable {
    margin-top: 0px !important;
}

#claimDetailComp .coverage-panel-body .dataTables_wrapper .row:nth-child(3) {
    display: none!important;
}

#claimDetailComp th.amt-right {
    text-align: right;
}

#claimDetailComp th.limit-right {
    text-align: right;
}

#claimDetailComp td.amt-right {
    text-align: right;
    padding-right: 50px !important;
}

#claimDetailComp td.limit-right {
    text-align: right;
    padding-right: 10px !important;
}

#claimDetailComp .p-loading-container {
    left: 0;
}

#claimDetailComp .showRemark {
    margin-left: -2px;
}

#blDialog .form-group .gwp-label {
    color: #337ab7 !important;
    width: 105px;
}
#blDialog .gwp-inline {
    margin-left: 10px;
}

#blDialog .dl-horizontal.gwp-dl > dt{
    text-align: left;
}

@media (min-width: 768px) {
    #claimDetailComp .totalAmtDt {
        text-align: right !important;
        width: 80px !important;
    }
    #claimDetailComp .totalAmtDd {
        margin-left: 100px !important;
    }
    #claimDetailComp .grpHgt {
        height: 102px !important;
    }
    #claimDetailComp .totalWd {
        width: 450px !important;
    }
    #claimDetailComp .notCoverWd {
        width: 400px !important;
    }
    #claimDetailComp .benefitLimtTd {
        padding-left: 50px !important;
        text-align: right;
    }
    #claimDetailComp .benefitAmtTd {
        padding-left: 100px !important;
        text-align: right;
    }
    #claimDetailComp .preNextClm {
        padding-left: 82px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    #claimDetailComp .preNextClm {
        padding-left: 10px !important;
    }
    #claimDetailComp .gwp-page-header {
        width: 153%;
    }
    #claimDetailComp .cm_preNextClaim {
        float: right;
        margin-top: 16px;
    }
}

@media (min-width: 993px) {
    #claimDetailComp .gwp-page-header {
        width: 135%;
    }
    #claimDetailComp .cm_preNextClaim {
        float: right;
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    #claimDetailComp .benefitMsg {
        margin-top: 10px;
    }
    #claimDetailComp .benefitLimtTd {
        padding-left: 30px !important;
        text-align: right;
    }
    #claimDetailComp .benefitAmtTd {
        padding-left: 55px !important;
        text-align: right;
    }
    #claimDetailComp .multiplePayDiv {
        padding-bottom: 15px;
    }
    #claimDetailComp .chargeDetailsAmountAllowed {
        padding-left: 0px;
    }
    #claimDetailComp .cm_preNextClaim {
        padding-bottom: 5px;
    }
    #claimDetailComp .cm-benefitLimitOthLmt {
        display: none;
    }
    #multiplePayeesDialog .dl-horizontal.gwp-dl > dt {
        text-align: left;
    }
    #blDialog .gwp-inline {
        margin-left: 0px;
    }
}
#claimDetailComp .marginTop0px {
    margin-top: 0px !important;
}