#help .fontBlue{
	color: #009ace !important;
}

#help .boldFont{
    font-weight: 800 !important;
}
  
#help .fontMessage{
  line-height: 2;
  font-size: 12px;
}

#help .gwp-dt-select-all{
  text-align: center;
}

#helpLocation .gwp-dt-select-all{
text-align: center;
}