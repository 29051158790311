#billingSummary .linkStyle {
	vertical-align: super ;
	text-decoration:underline ;
}

#billingSummary .fontWeight {
    font-weight: 900;
}

#billingSummary .alignRight {
	text-align: right;;
}

#billingSummary .dataTables_info {
	display: none;
}
#billingSummary .gwp-table {
	margin-top: 0px;
}

#billingSummary .printButton {
	border: 0; 
	background-color: transparent; 
	outline: none;
	color:#009ace;
	font-weight: bold;
	height: 31px;
	cursor: pointer;
	font-size:12px!important;
}
