#claimInquiryMember .gwp-btn{
	margin-top: 15px;
}
@media (max-width: 767px){
	#claimInquiryMember .gwp-btn{
		margin-top: 0px;
	}
}
@media (min-width: 993px){
	#claimInquiryMember .retrieveBtnDIV{
		margin-top: 5px;
		margin-left: -100px;
	}
}
@media (max-width: 767px){
	#claimInquiryMember .retrieveBtnDIV{
		margin-top: 10px;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	#claimInquiryMember .retrieveBtnDIV{
		margin-top: 5px;
	}
}