#enrollment .beneChkBox{
    margin-top:5px!important;
}
#enrollment .radChkBox{
    margin-top:5px!important;
}
#enrollment .reginnertitle {
	display: inline;
	color: #4abece;
	font-weight: bold;
	font-size: 14px;
}
#enrollment .stepTitle {
	margin: 10px 0px;
    clear: both;
    background-color: #f5f5f5;
    clear: both;
    padding:10px 0px;    
}
#enrollment .pull-right {
    float: right !important;
  }