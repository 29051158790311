body {
  display: none;
}
span.u-notification{
  background:orange!important;
    border-radius: 50%;
    padding: 5px;
    font-size: 9px;
    color: #fff;
    line-height: 4px;
    position: relative;
    right: 5px;
    
}
.mt-2 {
  margin-top: 20px;
}
.img-size-20{
  width: 20px;
}