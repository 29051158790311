#contactWarn .warnInfo {
    color: #888;
}
#emailLabel .gwp-icon-label{
    color: rgb(0, 154,206)
}
#changeAuth {
    margin-right: 5px !important;
}
#addEmail #deleteEmail {
    margin-left:0px im !important;
}
#auDiv {
    background: none repeat scroll 0% 0% rgb(242, 242, 242);
}
#updateLink #fontSize{
    font-size: 12px !important
}
#personalContactInfo .emailSplit {
    border-top: 2px solid gray;
    clear: both;
    margin-top: 10px;
    padding-top: 5px;
	padding-left: 0px;
}
#personalContactInfo .relateinfo dd {
    margin-left: 0;
    min-height: 17px;
    word-wrap:break-word;
}