.form-box {
    margin-top:20px;
}
.list-group {

    margin-bottom: 5px;
    text-decoration: none;
}
.list-onlineForm-item {
    border: none;
    text-decoration: dotted;
}