#licensing .hmi-divider {
  height: 40px;
  vertical-align: middle;
  padding-left: 15px;
  padding-top: 6px;
  font-weight: bold;
}
#licensing .retriveBtn {
  margin-top: 27px;
}

#licensing .align-bottom {
  vertical-align: bottom;
}

#licensing .mi-list-row {
  border-left-width: 15px;
  border-left-style: solid;
  border-left-color: #c9e7ac;
  border-bottom: 1px solid #e9eae7;
  border-right: 1px solid #e9eae7;
  margin: 0;
  border-left-color: #ccc;
}

#licensing .mi-list-row-title {
  color: #39afe6;
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: bold;
  padding-top: 10px;
  margin-top: 0;
}

#licensing .dl-horizontal dt {
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
}

#licensing .dl-horizontal dd {
  padding-top: 10px;
  padding-left: 15px;
}
#licensing .fullNameAndAddr {
  margin-bottom: 8px;
}
#licensing .address {
  line-height: 1.5;
  display: 'block';
}
#licensing .retrieveBtn {
  margin-top: -2px;
  margin-left: 0px;
}
#licensing .gwp-btn-container {
  display: inline-block;
}
#licensing .helpBanner {
  margin-top: 10px;
  float: left;
}
#licensing .helpBanner-oneagent {
  margin-right: 13px;
  float: right;
}
#licensing .no-record {
  text-align: center;
}

#licensing .gwp-combobox .rw-select.rw-select-bordered {
  display: table-cell;
}

#licensing .gwp-table {
  margin-top: -10px;
}

#licensing .drrow {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  #licensing .retrieveBtn {
    margin-left: 10px;
  }
  #licensing .gwp-btn-container {
    display: inline-block;
  }

  #licensing .helpBanner {
    margin-top: -24px;
    float: right;
  }
}