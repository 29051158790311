.list-box {
    margin-top:20px;
}

.list-group {
    margin-bottom: 0px;
    text-decoration: underline;
}

.list-group-item {
    border: none;
}


