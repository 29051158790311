#adjustmentsBilled .linkStyle {
	vertical-align: super ;
	text-decoration:underline ;
}

#adjustmentsBilled .fontWeight {
    font-weight: 900;
}

#adjustmentsBilled .alignRight {
	text-align: right;;
}

#adjustmentsBilled .dataTables_info {
	display: block;
}
#adjustmentsBilled .gwp-table {
	margin-top: 0px;
}

#adjustmentsBilled .printButton {
	border: 0; 
	background-color: transparent; 
	outline: none;
	color:#009ace;
	font-weight: bold;
	height: 31px;
	cursor: pointer;
	font-size:12px!important;
}

#adjustmentsBilled .adjustments-billed-bottom{
	margin-bottom: -15px;
}

#adjustmentsBilled .showAll {
	position: relative;
	margin-left: -6px;
}

#adjustmentsBilled input[type="checkbox"]:hover{
	cursor:not-allowed !important;
}