#mocksavecard .panel-title {
  font-size: 16px;
  font-weight: bold;
}
#mocksavecard .panel-default {
  font-size: 16px;
  width: '90%';
  border-color: #ddd;
  margin-top: 10px;
}

#mocksavecard .panel-body{
  padding: 15px;
  margin-top: 20px;
}

#mocksavecard .buttonstyle{
  margin-left: 0px;
}
#mocksavecard .hmi-divider {
    height: 40px;
    vertical-align: middle;
    padding-left: 15px;
    padding-top: 6px;
    font-weight: bold;
  }
  
  #mocksavecard .align-bottom {
    vertical-align: bottom;
  }
  
  #mocksavecard .mi-list-row {
    border-left-width: 15px;
    border-left-style: solid;
    border-left-color: #c9e7ac;
    border-bottom: 1px solid #e9eae7;
    border-right: 1px solid #e9eae7;
    margin: 0;
    border-left-color: #ccc;
  }
  
  #mocksavecard .pannel-heading {
    color: #39afe6;
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bold;
    padding-top: 10px;
    margin-top: 0;
  }
  #mocksavecard .gwp-btn-container {
    margin-top: 0px;
    display: inline-block;
  }
