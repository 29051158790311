#questionsForCategory .questionAnswerContainer {
  border-bottom: 1px solid #ccc;
  padding: 4px 0;
  width: 85%;
}
#questionsForCategory .question-collapsed:before {
  content: " ";
  border-width: 5px 5px 5px 0px;
  border: 5px solid black;
  border-color: transparent;
  border-left-color: black;
  position: absolute;
  margin-top: 2px;
}
#questionsForCategory .question-expanded:before {
  content: " ";
  border-width: 5px 5px 5px 0px;
  border: 5px solid black;
  border-color: transparent;
  border-top-color: black;
  position: absolute;
  margin-top: 5px;
}
#questionsForCategory .question {
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  color: #0063dc;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  margin-left: 15px;
  width: calc(100% - 75px);
  word-break: break-all;
  display: inline-block;
}
#questionsForCategory .question > .questionContent {
  padding-left: 15px;
}
#questionsForCategory .answer {
  margin-top: 5px;
}
#questionsForCategory .row > div > div > .gwp-btn-container {
  float: right;
  margin-top: 0px;
}

#questionsForCategory ul {
  padding: 0px;
}
#questionsForCategory .answer > ul {
  padding-left: 40px;
}
#questionsForCategory .answer > ol {
  padding-left: 40px;
}
#questionsForCategory ul > li > label {
  margin: 0px;
}
#questionsForCategory #editQuestion label,
.gwp-label,
.gwp-label-required {
  margin: 5px 0 5px 0;
}
#questionsForCategory #editQuestion ul > li {
  width: 50%;
  display: inline-table;
}
#questionsForCategory #editQuestion ul > li > label {
  display: inline;
}
#questionsForCategory #editQuestion ul > li > div > label {
  margin: 0px;
}
#questionsForCategory .btnGroup {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
#questionsForCategory .btnGroup > span {
  cursor: pointer;
  font-size: 14px;
  color: #009ace;
  padding-left: 5px;
}
#questionsForCategory .categoreis .gwp-label {
  margin-top: 10px;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  #questionsForCategory #editQuestion ul > li {
    width: 100%;
    display: inline-table;
  }
}
