#portalCategories .current {
  color: #337ab7;
  font-weight: bold;
}
#portalCategories .confirmSubmitMsg {
  margin-top: 5px;
}
#editWebPageModal .gwp-content-editor .gwp-information {
  margin-top: -15px;
}
