#groupTable {
	width: 100% !important;
}
.btnGroupDiv {
	margin-top : 25px;
	margin-bottom : 25px !important;
}
.checkbox-div {
	display : inline-block;
	margin-right: 5px;
}

.container table{    
	border: 1px solid #ddd;
	background-color: #F0F0F0;
	width : 100%
}

.container table th {
	border-bottom:none !important;
}

@media (max-width: 769px){
	.confirmRemove{
		margin: 10px 0;
	}
}

@media (min-width: 768px) { 
	.container table .colum1 {
		width:80%;
	}
}

.gwp-portlet-container .table-striped>tbody>tr:nth-of-type(odd){
	background-color: #f9f9f9;
}
.gwp-portlet-container .table-striped>tbody>tr:nth-of-type(even){
	background-color: #ffffff;
}


.container table .colum1 {
	width:75%;
}
.glyphicon-plus:before{

	top: 2px;
	left: -6px;
	height: 14px;
	width: 14px;
	display: block;
	position: absolute;
	color: white;
	border: 2px solid white;
	border-radius: 14px;
	box-shadow: 0 0 3px #444;
	box-sizing: content-box;
	text-align: center;
	font-family: 'Courier New', Courier, monospace;
	line-height: 14px;
	content: '+';
	background-color: #337ab7;
}


.glyphicon-minus:before{

	top: 2px;
	left: -6px;
	height: 14px;
	width: 14px;
	display: block;
	position: absolute;
	color: white;
	border: 2px solid white;
	border-radius: 14px;
	box-shadow: 0 0 3px #444;
	box-sizing: content-box;
	text-align: center;
	font-family: 'Courier New', Courier, monospace;
	line-height: 14px;
	content: '-';
	background-color: #d33333;

}