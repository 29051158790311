#releaseInformation .modal-message {
  margin-top: 0px;
}
#releaseInformation dl > dd > span {
  margin-right: 10px;
}
#releaseInformation .gwp-link-btn {
  padding-left: 0px;
  padding-right: 0px;
}
