#updatepayrollschedule .payrollSchedule{
    margin-left:82px;
}

#updatepayrollschedule .payrollSchedule4Firefox{
    width: 270px;
}
#updatepayrollschedule .rw-widget-container4Firefox {
    width: 270px !important;
    margin: 0;
}

@media (min-width: 431px) {
    #updatepayrollschedule .dl-horizontal dd {
        margin-left: 280px; 
    }
}

@media (max-width: 430px){	
#updatepayrollschedule .payrollSchedule{
        margin-left:0px;
    }
}

@media (min-width: 768px) and (max-height: 1024px){	
    #updatepayrollschedule .left-margin-4-spad label{
            width:310px;
        }

    #updatepayrollschedule .left-margin-4-spad dt{
        width:320px;
    }

    #updatepayrollschedule .payrollSchedule{
            margin-left:0px;
        }
    }