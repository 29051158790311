#otherAdjustment .gwp-page-header{
	width: 100%;
}
#otherAdjustment .gwp-currency-margin{
	margin-bottom: 10px;
}
#otherAdjustment .printButton{
	border: 0; 
	background-color: transparent; 
	outline: none;
	color:#009ace;
	font-weight: bold;
	height: 31px;
	cursor: pointer;
	font-size:12px!important;
}

#otherAdjustment .rightAlign{
	margin-top: -12px;
	float: right!important;
}

#otherAdjustment .enterinfo{
    font-size: 12px;
    font-weight: 400;
}
#otherAdjustment .jumpTo{
    font-size: 12px;
}
#otherAdjustment .dataTable {
	border-color: white!important;
}

#otherAdjustment .or {
    padding-top: 28px;
    margin-right: 5px;
    width: 20px;
}
#otherAdjustment .submitPlace {
    margin-top: 10px; 
	margin-bottom: 10px;
}
#otherAdjustment .other-adjustment-bottom{
	margin-bottom: -15px;
}

#otherAdjustment .showAll {
	margin-left: -4px;
}


