#policyDetail .breakWord{
	word-break: break-all;
}
/* #policyDetail .policy-title {
	font-weight: bold;
	font-size: 16px;
	display:inline-block;
} */
#policyDetail  .policy-document{
	float:right;
	font-size:12px;
}
@media (max-width: 400px){
	#policyDetail .policy-document{
		display:inline-block;
		float:none;
		font-size:12px;
	}
}


#policyDetail .policy-panel-body{
	padding:0 15px;
}

#policyDetail .dataTables_info {
	display: none;
}

#policyDetail .borderless{
	border:none;
	/* border-shadow:none; */
}

#policyDetail .detailTd {
	vertical-align: top;
	width: 50%;
}

	
#policyDetail .pd-header-panel {
	border-width:0 0 1px 0;	
}

#policyDetail .pd-header-panel > .panel-body {
	padding: 15px 0 0 0;
}
#policyDetail .pd-header-panel > .panel-body > .row {
	line-height:40px;
}

#policyDetail .pd-header-block {
	width:45px;
	height: 40px;
	margin-right: 10px;
	-webkit-box-shadow: 5px 0 2px #e8f4f6;
	-moz-box-shadow: 5px 0 2px #e8f4f6;
	box-shadow: 5px 0 2px #e8f4f6;
}

#policyDetail .pd-header-policynum {
	font-size:14px;
	font-weight:bold;
}

#policycDetail .test1 {
	margin:10px 0;
	display:inline-block;
}

#policyDetail .pd-agent-title .pd-policyinfo-title {

	height: 30px;    
	vertical-align: middle;
	padding-left: 15px;
	padding-top: 6px;
	font-weight:bold;
	font-size: 12px;
	line-height: 20px;

}

#policyDetail .pd-policyinfo-content hr {
	
	margin-top: 0;
}

#policyDetail .pd-agent {
	border-color:#d9edf7;	 
	border-width: 0 1px 1px 1px;
	border-style: solid;   

}

#policyDetail .pd-agent hr {
	
	margin:0 auto;
	width:90%;
	color: gray;
}


#policyDetail .pd-agentcontent span {
	
	padding-left: 15px;	
	font-size: 12px;
	font-weight: bold;

}

#policyDetail .dl-horizontal dt{
	color:#337ab7;
	text-align:left;
	margin-bottom: 0px;
}

#policyDetail .dl-horizontal2 dt{
	color:black !important;
	text-align:left
}

#policyDetail .pd-view-all-agents{	
	padding-left:0;
}

#policyDetail .modal{
	margin: 0 auto;	
}

#policyDetail .list .agentInfo .agentName{
width:100%;
margin-bottom:2px;
}

#policyDetail .agentInfo .gwp-label-ro {
margin:0;
}

#policyDetail .gwp-dl > dt{
	min-width:30px;
}

#policyDetail .agentInfo a{
	word-wrap:break-word;
	/* margin-left:5px; */
}

#policyDetail .glyphicon-triangle-right {
	margin-top:10px;
}

#policyDetail .agentInfo hr {
margin: 10px 0;
}

#policyDetail .pd-dividend-summary .gwp-dl {
margin-bottom:0px;
}

#policyDetail .list .agentInfo dt {
text-align:left;
width:20%;
margin-bottom:1px;
}

#policyDetail .list .agentInfo dd{
margin-left:0;
word-break: break-all;
}

#policyDetail .detail dt {
padding-left:0;
padding-right:0;
}

#policyDetail .agentInfo dl {
margin-bottom:15px;
}

#policyDetail .agentInfo a {
	margin-left: 5px;
}

#policyDetail .panel-body .list{
padding-left:0;
margin-bottom:0;
}

#policyDetail .benefit-panel-body .dataTable{
margin-top: 0 !important;
border:0;
}

#policyDetail .table-bordered>thead>tr>td,#policyDetail .table-bordered>tbody>tr>td,#policyDetail.table-bordered>thead>tr>th{

border:0;
}
#policyDetail .pd-dividend-summary,.pd-coupon-summary {
	margin-left:22px;
	white-space:nowrap;
}
#policyDetail .pd-policyinfo-content div:nth-child(1) dl,#policyDetail .pd-policyinfo-content div:last-child dl:not(:last-child){

	border-bottom: 2px solid #999;
}
@media (min-width: 768px){
	#policyDetail .pd-policyinfo-content div:nth-child(1) dl,#policyDetail .pd-policyinfo-content div:last-child dl:not(:last-child){
		width: 80%;
	}
}

#policyDetail .pd-policyinfo-content .pd-dividend-summary div dl,#policyDetail .pd-policyinfo-content .pd-coupon-summary  dl {
	border:0;
}
#policyDetail #changeOW1DIV .gwp-btn-container{
	margin-top: 0px;
}

		
#policyDetail .detailTable1{
	width: 100%;
}
#policyDetail .detailTable2{
	width: 94%;
}
@media (min-width: 768px){
	#policyDetail .pd_currentDate{			
			float:left;
		}		
		#policyDetail .detailTable1{
		width: 55%;
	}
	#policyDetail .detailTable2{
		width: 52%;
	}
}

@media (min-width: 992px){
	#policyDetail .pd_currentDate{
			
			float:right;
			margin-top:16px;
		}		
		#policyDetail .gwp-page-header{
			width:135%;
		}
}

#policyDetail #gias-pd #peronalInfo {
	float: left;
}
#policyDetail #gias-pd #peronalInfo th {
	text-align: left;
	width: 78px;
}

#policyDetail #gias-pd .policyInfo dl + dl{
	border-top: 2px solid gray;
	width: 80%;
}
#policyDetail #gias-pd #pdo {
	margin-left: 20px;
}
#policyDetail #gias-pd #pdo dd {
	margin-left: 0px;
}
#policyDetail #gias-pd #pdo th,#gias-pd #pdo td {
	margin: 0;
	padding: 0;
}
#policyDetail #gias-pd #pdo th {
	text-align: left;
}
#policyDetail table .beneficiaryTbl td{
	padding: 0;
	margin: 0;
	width: 200px;
}

#policyDetail #allBeneficiariesList{
	width: 430px;
}
#policyDetail #allBeneficiariesDialog li {
	display: block;
	width: 210px;
	float: left;
	margin-bottom: 12px
}
#policyDetail #allBeneficiariesDialog li h6 {
	color: #009e42;
}
#policyDetail #allBeneficiariesDialog li dt {
	width: 200px;
	display: inline-block;
	color: #009ace;
}
#policyDetail #allBeneficiariesDialog li dd {
	width: 80px;
	display: inline-block;
}
#policyDetail #allBeneficiariesDialog #closeAllBeneficiariesDialog {
	clear: both;
	display: block;
}


#policyDetail #dividendDetailsDialog #closeDividendDetailsDialog {
	clear: both;
	display: block;
}

#policyDetail .pdclass1{
	padding: 0px 20px !important; 
	text-align: left !important;
}
	
#policyDetail .pd_currentDate{
	font-weight:bold;
	font-size:12px;
}

#policyDetail .panel .dataTables_wrapper table.dataTable .dtr-data,#policyDetail .dataTables_wrapper table.dataTable .dtr-details tr{
	border-bottom: 1px solid #ccc;
	line-height: 20px;
}

#policyDetail .dataTables_wrapper table.dataTable .dtr-details tr td.dtr-data{
	text-align:right;
}

#policyDetail .pd-view-all{
	margin-top:5px;
}

#policyDetail #successUpdateInfo .msgTitle {
	margin-bottom: 5px;

}

#policyDetail .gwp-icon-validate {
background-size: 13px;
float: left;
height: 60px;
width: 13px;
margin-bottom: -1px;
padding: 10px;
}

#policyDetail .policy-information-body{
	padding: 0 15px;
}

#policyDetail .panel-body .gwp-table{
	margin-top:0;
	display: block;
}

#policyDetail .modal-body .gwp-table{
	margin-top:0;
	display: block;
}

#policyDetail .modal-body{
	padding: 15px 15px 15px 15px !important;	
}


#policyDetail .panel-body .dataTable{
	margin-top:0px!important;
}

#policyDetail .AgentPhone {
	text-align:left;
	width:20%;
	margin-bottom:1px;
}