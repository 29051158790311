#policyComments .noBorder{
    border:none;
}

#policyComments .gwp-btn-container{
    margin-top: 0px;
}

@media (min-width: 768px) {
    #policyComments .gwp-dl > dt {
      width: 160px !important;
    }
    #policyComments .gwp-dl > dd {
      /*width: 70% !important;*/
      margin-left: 0px;
    }
    #policyComments .gwp-dl.marginLeft180px > dd {		
		margin-left: 160px;
	  }
  }

@media (max-width: 767px) {
  #policyComments .gwp-dl > dt {
    width: 90% !important;
  }
  #policyComments .gwp-dl > dd {
    width: 90% !important;
    margin-left: 0px;
  }
  #policyComments .panel-default>.panel-heading {
     margin-top: 10px;
  }
}

#policyComments .app-detail-dl dt{
	text-align:left;	
}
#policyComments .panel-default>.panel-heading{
  border-bottom: 0px;
}
#policyComments .panel {
  -webkit-box-shadow: 0 0px 0px rgb(0 0 0 / 5%); 
   box-shadow: 0 0px 0px rgb(0 0 0 / 5%);
}
#policyComments .paddingLeft0px{
	padding-left: 0px;	
}
#policyComments .scroll50px{
  overflow-y: scroll;
  height: 120px;
  margin-top: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border: 1px solid !important;
  border-color:#ddd!important;
  border-radius:5px;
}
#policyComments .dl-horizontal > dt{
  text-align: left !important;
}
#policyComments .noBorder{
  border: none;
}

#policyComments .border{
  border: 1px solid transparent;
}
