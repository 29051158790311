
#gias-pd #peronalInfo {
	float: left;
}

#gias-pd #peronalInfo th {
	text-align: left;
	width: 78px;
}

#gias-pd .policyInfo dl+dl {
	border-top: 2px solid gray;
	width: 80%;
}

#gias-pd #pdo {
	margin-left: 20px;
}

#gias-pd #pdo dd {
	margin-left: 0px;
}

#gias-pd #pdo th, #gias-pd #pdo td {
	margin: 0;
	padding: 0;
}

#gias-pd #pdo th {
	text-align: left;
}

#applicationDetail table .beneficiaryTbl td {
	padding: 0;
	width: 200px;
}

#allBeneficiariesList {
	width: 430px;
}

#allBeneficiariesDialog li {
	display: block;
	width: 210px;
	float: left;
	margin-bottom: 12px
}

#allBeneficiariesDialog li h6 {
	color: #009e42;
}

#allBeneficiariesDialog li dt {
	width: 200px;
	display: inline-block;
	color: #009ace;
}

#allBeneficiariesDialog li dd {
	width: 80px;
	display: inline-block;
}

#allBeneficiariesDialog #closeAllBeneficiariesDialog {
	clear: both;
	display: block;
}

#dividendDetailsDialog {
	width: 720px;
}

#dividendDetailsDialog #closeDividendDetailsDialog {
	clear: both;
	display: block;
}

#dividendDetailsDialog tbody th, #dividendDetailsDialog tbody td {
	border-top: 1px solid white;
}

#dividendDetailsDialog tbody tr[scope="even"] {
	background-color: #edebdb;
}

#applicationDetail .pdclass1 {
	padding: 0px 20px !important;
	text-align: left !important;
}

#appAct {
	float: right;
	font-weight: bold;
	font-size: 12px;
	margin-right: 20px;
}

#applicationDetail .emailSpan {
	padding: 0 10px;
	word-break: break-all;
	display: inline-table;
	width: 245px;
}

#applicationDetail .defaultColor {
	background-color: transparent !important;
}

#applicationDetail td.tdAlignTop {
	vertical-align: top;
}

#applicationDetail .moreInfo table td {
	background-color: #D4F3B7;
	border-top: 1px solid white;
}

#applicationDetail .moreInfo table {
	border-spacing: 0px;
	margin-bottom: 4px;
	width: 98%;
	padding-left: 40px;
}

#applicationDetail table.giasGrid tr.moreInfo table {
	min-width: 50%;
}

#applicationDetail table.giasGrid .moreInfo th {
	text-align: left;
}
 
#applicationDetail table.giasGrid tr.moreInfo th, table.giasGrid tr.moreInfo td {
	padding: 0;
}

 

#applicationDetail .emailPadding {
	padding-left: 10px;
}

#applicationDetail .app-detail-header-block {
		width:45px;
		height: 40px;
		margin-right: 10px;
		-webkit-box-shadow: 5px 0 2px #e8f4f6;
		-moz-box-shadow: 5px 0 2px #e8f4f6;
		box-shadow: 5px 0 2px #e8f4f6;
}

#applicationDetail .app-detail-header-appnum {
		font-size:14px;
		font-weight:bold;
}

#applicationDetail .app-detail-header-panel {
		border-width:0 0 1px 0;	
}

#applicationDetail .app-detail-header-panel > .panel-body {
		padding: 15px 0 0 0;
}

#applicationDetail .app-detail-header-panel > .panel-body > .row {
		line-height:40px;
}

#applicationDetail .app-detail-dl dt{
	text-align:left;
	
}

#applicationDetail .app-detail-view-all{
	margin-top:5px;
}

#applicationDetail .list .agentInfo .agentName{
	width:100%;
	margin-bottom:2px;
}

#applicationDetail .list .agentInfo dt {
	text-align:left;
	width:20%;
    margin-bottom:1px;
}

#applicationDetail .list .agentInfo dd{
	margin-left:0;
	width:80%;
}

#applicationDetail .detail dt {
	padding-left:0;
	padding-right:0;
}

#applicationDetail .agentInfo dl {
	margin-bottom:5px;
}

#applicationDetail .panel-body .list{
	padding-left:0;
	margin-bottom:0;
}
 
#applicationDetail .agentInfoGrid dt:not(:first-child){
	 width:20%;
 }
 
 
 
 #applicationDetail .agentInfoGrid-xs dt {
	 float:left;
	 width:45%;
 }
 
 #applicationDetail .agentInfoGrid dd{
	 margin-left:0 !important;
 }

 #applicationDetail .app-detail-dl {
	
	 margin-left:0;
}
#applicationDetail .app-detail-agent {
	border-color:#d9edf7;	 
	border-width: 0 1px 1px 1px;
	border-style: solid;   

}

#applicationDetail .app-detail-agent-title {
	
		height: 30px;    
		vertical-align: middle;
		padding-left: 15px;
		padding-top: 6px;
		font-weight:bold;
		font-size: 12px;
		line-height: 20px;

}

#applicationDetail .app-detail-agent-title .glyphicon-user {
		
	 position:relative;
}

#applicationDetail .app-detail-agent-title .glyphicon-user > span {
		
	 position:absolute;
	 left:3px;top:8px;
	 height:0px;
	 width:0px;
	 border-top: 6px solid #ffffff;
	 border-left: 3px solid transparent;
	 border-right: 3px solid transparent;
}

#applicationDetail .app-detail-agent hr {
		
	margin:0 auto;
	width:90%;
	color: gray;
}
	
#applicationDetail .app-detail-agentcontent{
	margin-top:5px;
}

#applicationDetail .app-detail-agentcontent span {
		
	padding-left: 15px;	
	font-size: 12px;
	font-weight: bold;
}

#applicationDetail .dl-horizontal dt{
	text-align:left
}

#applicationDetail .app-detail-panel{
	margin-top: 10px;
	border:0;
	border-color:#ddd;
}

#applicationDetail .app-ai-detail-panel{
	margin-top: 10px;
	border-color:#ddd;
}
	
#applicationDetail .app-detail-view-all-agents{
		
	padding-left:0;
	margin-top:10px;
}

#applicationDetail .app-detail-panel-body{
	padding:0 15px;
}
#applicationDetail .app-detail-title {
	font-weight: bold;
	font-size: 16px;
}

#applicationDetail .agentnum-firstlastname {
	font-weight:bold;
}

#applicationDetail .bd-agentcontent {
	margin-bottom:5px;
}
#applicationDetail .agentInfo .gwp-label-ro {
		
	margin:0;
}

#applicationDetail .agentEmail{
	margin-left:5px;
}

#applicationDetail .glyphicon-triangle-right {
	margin-top:0px;
}

#applicationDetail .dl-horizontal.gwp-dl > dt {
	margin-bottom:0px;
	width: auto;
}

#applicationDetail .borderless{
		border:none;
		margin-bottom:0;
}

#applicationDetail .dtr-details .gwp-dl dt{
	word-break: break-all;
}
#applicationDetail .dtr-details{
	margin-bottom: 0px;
}
#applicationDetail .dtr-details .gwp-dl{
    margin-bottom: 0px;
}

#applicationDetail .gwp-portlet-container .panel .dataTables_wrapper table.dataTable .dtr-data{
	padding-left:10px;
}

#applicationDetail .agentInfoGrid-xs > span,.agentInfoGrid-xs dd  {
	
    white-space: nowrap;

}
#applicationDetail .agentInfoGrid-xs dd{
	margin-top:10px;
}

@media (max-width:768px){
	#appAct {
		margin-right:0;
	}
}
#applicationDetail .dataTables_info {
    display: none;
  }

  @media (min-width: 768px) {
    #applicationDetail .gwp-dl > dt {
      /*width: 30% !important;*/
    }
    #applicationDetail .gwp-dl > dd {
		/*width: 70% !important;*/
		margin-left: 60px;
	  }
	  #applicationDetail .dl-horizontal.gwp-dl.marginLeft180px > dd {		
		margin-left: 160px;
	  }
	  #applicationDetail .dl-horizontal.gwp-dl > dd {
		margin-left: 60px;
	}
  }

@media (max-width: 767px) {
  #applicationDetail .gwp-dl > dt {
    width: 90% !important;
  }
  #applicationDetail .gwp-dl > dd {
    width: 90% !important;
	margin-left: 0px;
  }
}

#applicationDetail .coverage-panel-body{
	padding: 0px !important;
}

#applicationDetail table.dataTable {
	margin-top: 0px !important;
	border: none !important;
}
#applicationDetail .gwp-table {
	margin-top: 0px;
  }

  #applicationDetail .dataTables_empty {
	display: none;
  }
  #applicationDetail  .table-bordered>thead>tr>th{
	border: none !important; 
  }

  @media(max-width:768px){
  #applicationDetail  .marginButtom10px{
	margin-bottom: 10px; 
  }
}
#applicationDetail  .marginRight10px{
	margin-right: 10px; 
  }
  #applicationDetail  .marginLeft5px{
	margin-left: 5px; 
  }
  #applicationDetail .paddingLeft15px{
	padding-left: 15px;
  }
  
  #applicationDetail .gwp-dl.marginLeft0px> dd{
	margin-left: 0px !important;
  }
  #applicationDetail .marginTop10px{
	margin-left: 10px;
  }
  #applicationDetail .fontBlue{
	color: #009ace !important;
  }