#portalcontent .fontBlue{
	color: #009ace !important;
}

#portalcontent .boldFont{
    font-weight: 800 !important;
}
  
#portalcontent .fontMessage{
  line-height: 2;
  font-size: 12px;
}

#portalcontent .gwp-dt-select-all{
  text-align: center;
}