#makePayment .pd-header-panel {
  border-width: 0 0 1px 0;
}
#makePayment .makePayment-info-body {
  padding-top: 10px;
  padding-left: 55px;
}

#makePayment .panel-body {
  padding: 15px 0 15px 0;
}
#makePayment .pd-header-policynum {
  font-size: 14px;
  font-weight: bold;
}

#makePayment .pd-fontbold {
  font-weight: bold;
}

#makePayment #successUpdateInfo .msgTitle {
  margin-bottom: 5px;
}

#makePayment .printPlace {
  float: right;
  margin-top: -7px;
}

#makePayment .modalContent {
  margin-left: 1px;
}

#makePayment .panelCCBody {
  margin-left: 13px;
}

#makePayment .modalHr {
  margin-top: 4px;
}

#makePayment .viewWallet {
  font-size: 12px;
}

#makePayment .iconWalletSpace {
  margin-right: 4px; 
  margin-left: 6px;
}

#makePayment .normalText {
  font-weight: normal !important;
}

#makePayment .helpBanner {
  float: right;
}

@media (min-width: 768px) {
#makePayment .gwp-page-header {
    width: 152%;
  }
#makePayment .help-banneer {
    float: right;
  }
}
@media (max-width: 768px) {
#makePayment .gwp-currency-margin {
    margin-bottom: 10px;
    margin-top: 10px;
  }
#makePayment .gwp-payment-overflow {
    overflow: scroll;
  }

#makePayment .helpBanner {
	float: left; 
}

#makePayment .panelHeadSize {
  font-size: 14px;
}
}

@media (min-width: 768px) {
#makePayment .modal-content {
    width: 95%;
    margin: auto;
  }
}
#makePayment .errorMsg {
  margin-right: 10px;
}

#makePayment .currencyMsg dt{
  font-weight: normal;
}

#makePayment .iconSpace{
  margin-right: 4px;
  margin-left: 2px;
}

#makePayment .successicon {
  margin-right: 10px;
}

#makePayment .gwp-currency-margin {
  margin-bottom: 10px;
}
#makePayment .gwp-mp-panelbottom {
  margin-bottom: 0px !important;
}

#makePayment .has-error .giasInput {
  height: 20px;
  border: 1px solid #cccccc;
  border-color: #a94442;
}
#makePayment .inline {
  display: inline;
}
#makePayment .closeinDialog {
	text-align: left;
}
