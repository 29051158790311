#contactWarn .warnInfo {
    color: #888;
}
#emailLabel .gwp-icon-label{
    color: rgb(0, 154,206)
}
#changeAuth {
    margin-right: 5px !important;
}
#addEmail #deleteEmail {
    margin-left:0px im !important;
}
#auDiv {
    background: none repeat scroll 0% 0% rgb(242, 242, 242);
}
#updateLink #fontSize{
    font-size: 12px !important
}
#agentContactInfo .emailSplit {
    border-top: 2px solid gray;
    clear: both;
    margin-top: 10px;
    padding-top: 5px;
	padding-left: 0px;
}
#agentContactInfo .relateinfo dd {
    margin-left: 0;
    min-height: 17px;
}

@media (min-width:1024px ) {
	#agentpersonalinformation .helpBanner {
		float: right;
	}
    #agentpersonalinformation .maxHide{
        display: block;
        float: right;
        margin-top: -105px;
    }
    #agentpersonalinformation .minHide{
        display: none;
    }
}
@media (max-width: 1023px) {
    #agentpersonalinformation .helpBanner {
        float: left !important;
		margin-bottom: 20px !important;
		margin-top: 20px !important;
    }	
    #agentpersonalinformation .minHide{
        display: block;
    }
    #agentpersonalinformation .maxHide{
        display: none;
    }
} 
@media (max-width: 415px) {
    #agentContactInfo .emailControl{
        margin-top: 10px !important;
    }
}
