#pendingTransactions .req-msg {
	margin-right: 3px;
}

#pendingTransactions .gwp-page-title{
    font-weight: 600;
    font-size: 20px;
	margin: 0;
	margin-left: 0;
}

#pendingTransactions .gwp-btn-container{
	margin-top:1px !important;
}

#pendingTransactions .blankup {
	margin-top: 8px;
}
#pendingTransactions .blankdown {
	margin-top: 10px;
}