.force-w100 {
    max-width: 100%!important;
}
.force-w100 .gwp-input{
	max-width: 100%!important;
}
.loader_submit_form{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    background: #888888cc;
    z-index: 9;
}
.loader_submit_form span{
    position: relative;
    top: 50%;
    font-size: 25px;
    color: #fff;
}