#terminatedcoveragerecorddisplay,
#durationdiv .form-group {
  display: inline-block !important;
}

#terminatedcoveragerecorddisplay .showDIV {
  display: block !important;
}
#terminatedcoveragerecorddisplay .hideDIV {
  display: none !important;
}
#terminatedCoverageRecordDisplay .dl-horizontal.gwp-dl > dt {
  width: 80px !important;
}
#terminatedCoverageRecordDisplay .dl-horizontal.gwp-dl > dd {
  margin-left: 0px !important;
  margin-bottom: 5px !important;
}
#terminatedCoverageRecordDisplay .hinttop {
  margin-bottom: 10px !important;
}
#terminatedCoverageRecordDisplay .hintbottom {
  margin-bottom: 0px !important;
}
#terminatedCoverageRecordDisplay .wordbreakall {
  word-break: break-all;
  min-width: 200px;
}
#terminatedCoverageRecordDisplay .gwp-inputAddOn {
  margin-top: 0px;
}
@media (min-width: 768px) {
  #terminatedCoverageRecordDisplay #retriveButtondiv .gwp-btn-container {
    margin-top: 30px;
  }

}
#terminatedCoverageRecordDisplay .gwp-dl > dd {

  word-break: break-all;
  word-wrap:break-word;
}  
#terminatedCoverageRecordDisplay td{
  word-break: break-all;
  word-wrap:break-word;
}
@media (max-width:768px){
  #terminatedCoverageRecordDisplay .dl-horizontal.gwp-dl > dd {
    padding-top:10px !important;
    margin-left: 0px !important;
    margin-bottom: 5px !important;
    padding-right:10px;
    word-break: break-all;
    word-wrap:break-word;
  }  
  #terminatedCoverageRecordDisplay td{
    word-break: break-all;
    word-wrap:break-word;
  }
}
