.statement-box {
    margin-top:20px;
}

.statement-group {
    margin-bottom: 20px;
    text-decoration:underline;
}

.list-statement-item {
    border: none;
    color: black;
}