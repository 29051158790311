#myprofile .margintop5px,
#profile .margintop5px {
  margin-top: 5px !important;
}
#myprofile .marginright10px,
#profile .marginright10px {
  margin-right: 10px !important;
}
#myprofile .margintop10px,
#profile .margintop10px {
  margin-top: 10px !important;
}
#myprofile .marginbottom10px,
#profile .marginbottom10px {
  margin-bottom: 10px !important;
}

#myprofile input[type="checkbox"].gwp-input,
#profile input[type="checkbox"].gwp-input{
  margin-top: 0px !important;
}


#myprofile .gwp-btn-container ,
#profile .gwp-btn-container  {
  margin-top: 0px !important;
}

#myprofile .gwp-link-btn ,
#profile .gwp-link-btn ,
#myprofile .fontred ,
#profile .fontred  {
  color: #A94442 !important;
  font-weight: 700;
  text-decoration: underline;
}

#myprofile .fontgray,
#profile .fontgray  {
  color: gray !important;
  
}