#title_confirmDelete .gwp-icon-warning{
    height:23px!important;
    margin-right:3px!important;
}
#initialBenefitSelectionperiod .titleRequiredBegin {
    margin-left:5px;
    font-size: 12px!important;
    font-weight: normal!important;
}
#initialBenefitSelectionperiod .titleRequiredStart{
    color: red!important;
    font-size: 12px!important;
    font-weight: normal!important;    
}
#initialBenefitSelectionperiod .titleRequiredText {
    font-size: 12px!important;
    font-weight: normal!important;   
}
#initialBenefitSelectionperiod .titleRequiredEnd {
    font-size: 12px!important;
    font-weight: normal!important;
}


