#toleranceAdjustment .gwp-page-header {
	width: 100%;
}

#toleranceAdjustment .rightAlign {
	margin-top: -12px;
	float: right !important;
}

#toleranceAdjustment .currencyAlign {
	margin-bottom: 10px;
}

#toleranceAdjustment .panelText {
	margin-left: -15px;
}

