#faq .second-level-title {
  color: #767676;
  font-size: 14px;
  font-family: "Lucida Sans";
}
#faq ul {
  padding: 10px;
  width: 90%;
}
#faq ul > li {
  padding: 5px;
  width: 50%;
  display: inline-table;
}

#faq ul > li > a {
  margin-left: 10px;
}
#faq ul > li > img {
  margin-top: -2px;
}
#faq ul > li > label {
  margin: 0px;
}

#faqQuestion .questionContainer {
  margin-top: 15px;
}

#faqQuestion .questions {
  margin-bottom: 15px;
}

#faqQuestion .question {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  color: #0063dc;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  margin-left: 15px;
  width: 100%;
  word-break: break-all;
  display: inline-block;
}

#faqQuestion .question-collapsed:before {
  content: " ";
  border-width: 5px 5px 5px 0px;
  border: 5px solid black;
  border-color: transparent;
  border-left-color: black;
  position: absolute;
  margin-top: 1px;
}
#faqQuestion .question-expanded:before {
  content: " ";
  border-width: 5px 5px 5px 0px;
  border: 5px solid black;
  border-color: transparent;
  border-top-color: black;
  position: absolute;
  margin-top: 4px;
}

#faqQuestion .question > .questionContent {
  padding-left: 15px;
}
#faqQuestion .answer {
  margin-top: 5px;
}
#faqQuestion .question:hover {
  background-color: azure;
}

@media screen and (max-width: 767px) {
  #faq ul > li {
    width: 100%;
  }
}
