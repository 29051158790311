#productionReports .noBorder{
    border: none;
}
#productionReports .noBackground{
    background-color: unset;
}
#productionReports .panel-heading{
    background-color: unset;
    border: none;
}
#productionReports .fontBlue{
    color: #009ace;
}
#productionReports .panel-heading>.panel-title>a{
    color: #009ace;
}