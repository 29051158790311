#benefitdetail .gridTitleChart {
    background: none repeat scroll 0 0 #C9E7DE;
    color: #585858;
    font-size: 16px;
    font-weight: bold;
    height: 26px;
    line-height: 26px;
    margin-bottom: 1px;
    padding: 5px;
    text-align: left;
}

#benefitdetail .list .agentInfo .agentName{
	width:100%;
	margin-bottom:2px;
	}
	
#benefitdetail .agentInfo .gwp-label-ro {
	margin:0;
	}

#benefitdetail .agentInfo .gwp-label-ro {
	margin:0;
	}
#benefitdetail .agentInfo a{
	word-wrap:break-word;
	}

#benefitdetail .agentInfo hr {
	margin: 10px 0;
	}
#benefitdetail .list .agentInfo dt {
	text-align:left;
	width:20%;
	margin-bottom:1px;
	}
				
#benefitdetail .list .agentInfo dd{
	margin-left:0;
	word-break: break-all;
	}
#benefitdetail .agentInfo dl {
	margin-bottom:15px;
	}
#benefitdetail	.agentInfo>.panel-body{
	padding:15px!important;
}
#benefitdetail	.agentInfo{
    float: right;
    margin-right: 16px;
    width: 100%;
}
#benefitdetail .panel-container-bottom>.panel-body{
	padding:5px!important;
	margin-bottom: 10px;
}
	
#benefitdetail .panel-container-bottom{
    float: right;
    margin-right: 16px;
    width: 100%;
}
	
#benefitdetail .bd-agent hr {
		
	margin:0 auto;
	width:90%;
	color: yellow;
}
#benefitdetail .gwp-icon {
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 5px;
}

#benefitdetail audio,#benefitdetail canvas,#benefitdetail progress,#benefitdetail video {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 20px;
}
#benefitdetail table.dataTable {
    clear: both;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    max-width: none !important;
	border: none !important;
}
#benefitdetail .gwp-table {
	margin-top: 0px;
	border-bottom: 0;
	border-right: 0;
	border-top: 0;
	border-left:0;
}

#benefitdetail .gwp-innertitle {
	padding: 5px 15px;
	width:100%!important;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	margin-bottom:15px;   
}
#benefitdetail .dl-horizontal dt{
	text-align:left;
	margin:0;
}
	 
#benefitdetail .benefit_fundChart{
	float: right;
}
#benefitdetail .table-bordered>thead>tr>td,#benefitdetail .table-bordered>tbody>tr>td,#benefitdetail .table-bordered>thead>tr>th{
	
	border:0;
}

#benefitdetail .marginbottom10px {
	margin-bottom: 10px !important;
}

@media (min-width: 992px){		
#benefitdetail .gwp-page-header{
	width:134%;
}
}
@media (width: 768px){
	#benefitdetail	.agentInfo{
		float: left;
		width: 190%;
	}
	#benefitdetail .panel-container-bottom{
		float: left;
		width: 190%;
	}
	#benefitdetail .bd-payoutinfo-content div:nth-child(1) dl,#benefitdetail .bd-payoutinfo-content div:last-child dl:not(:last-child){
		width: 80%;
	}
	#benefitdetail .bd-payoutinfo-content div:nth-child(2) dl,#benefitdetail .bd-payoutinfo-content div:last-child dl:not(:last-child){
		width: 80%;
	}
}
@media (min-width: 768px){
	#benefitdetail .bd-payoutinfo-content div:nth-child(1) dl,#benefitdetail .bd-payoutinfo-content div:last-child dl:not(:last-child){
		width: 80%;
	}
	#benefitdetail .bd-payoutinfo-content div:nth-child(2) dl,#benefitdetail .bd-payoutinfo-content div:last-child dl:not(:last-child){
		width: 80%;
	}
}

@media (max-width: 430px){
	#benefitdetail .agentInfo {
		float: right;
		margin-right: 16px;
		width: 91%;
		margin-top: 15px;
	}

	#benefitdetail .panel-container-bottom{
		float: right;
		margin-right: 16px;
		width: 91%;
	}
}
	
	#benefitdetail .bd-payoutinfo-content .pd-dividend-summary div dl,#benefitdetail .bd-payoutinfo-content .pd-coupon-summary  dl {
		border:0;
	}
	#benefitdetail .bd-payoutinfo-content hr {
		margin-top: 0;
	}
	#benefitdetail .payout-information-body{
		padding: 0 15px;
	}
	#benefitdetail .underline{
		border-bottom: 2px solid #999;
	}

	#benefitdetail .bd-payoutinfo-content div:nth-child(2) dl,#benefitdetail .bd-payoutinfo-content div:last-child dl:not(:last-child){

		border-bottom: 2px solid #999;
	}

	
	#benefitdetail .bd-payoutinfo-content .pd-dividend-summary div dl,#benefitdetail .bd-payoutinfo-content .pd-coupon-summary  dl {
		border:0;
	}

	#benefitdetail .secLine {
		height: 65px;
	}