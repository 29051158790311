#manageFaqCategory ul {
  padding: 10px;
  width: 90%;
}
#manageFaqCategory ul > li {
  padding: 0px;
  width: 50%;
  display: inline-table;
}
#manageFaqCategory ul > li .gwp-label {
  width: calc(50% - 0px);
  margin-right: 0px;
}
#manageFaqCategory .oldCateName {
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  #manageFaqCategory ul > li {
    width: 100%;
  }
  #manageFaqCategory ul > li .gwp-label {
    width: calc(100% - 35px);
  }
}
