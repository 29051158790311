#managePortalCategories .portalCategoryLabel {
  padding: 4px 0 10px 0;
  font-weight: normal;
  display: inline;
}

#managePortalCategories .selectUserLabel {
  padding-bottom: 15px;
}

#managePortalCategories .portalCategory {
  padding: 5px 0 5px 0;
}

#managePortalCategories input[type="radio"].gwp-input {
  margin-top: 0px;
}
#managePortalCategories .editPage {
  margin: 0px 0 0px -8px;
}
