#walletContainer .wallet-information {
  margin-top: 10px !important;
  margin-left: 15px !important;
  width: 97% !important;
}

#walletContainer .wallet-efinf {
  margin-top: 15px !important;
  margin-left: 15px !important;
  width: 97% !important;
}
#walletContainer .wallet-ccinf {
  margin-top: 15px !important;
  margin-left: 15px !important;
  width: 97% !important;
}
#walletContainer .wallet-link-font {
  font-size: 12px !important;
  font-weight: 600;
}
#walletContainer .gwp-icon-label {
  font-size: 12px;
}

#walletContainer .modal-footer {
  padding: 15px 15px 15px 30px !important;
  text-align: left;
}
#walletContainer .wallet-footer-button {
  margin-top: -10px;
}

#walletContainer .gwp-label-required {
  color: black !important;
}

#walletContainer .dataTable {
  border-color: white;
}

#walletContainer .printPlace {
  margin-top: -10px;
  float: right;
}

#walletContainer .modal-message {
  margin-top: 0px;
}
#walletContainer .hrstyle {
  margin-top: 4px;
  margin-bottom: 15px;
}

#walletContainer .messageIcon {
  margin-right: 10px;
}
#walletContainer .wallet-del-info {
  margin-bottom: 10px;
}
#walletContainer .payout-information-body {
  margin-top: 0px;
  margin-bottom: 0px;
}
#walletContainer .dl-horizontal dt {
  text-align: left;
  padding-left: 0px;
  padding-top: 0px;
}
#walletContainer .dl-horizontal dd {
  padding-top: 0px;
  padding-left: 0px;
}

#walletContainer .selectedRow {
  background-color: #acbad4 !important;
}
#walletContainer .gwp-table {
  margin-top: 0px;
}

#walletContainer .gwp-information {
  margin-left: 2px;
}
#walletContainer .modal-header {
  padding-left: 30px;
  padding-bottom: 15px;
  padding-top: 10px;
  border-bottom: 1px solid #e5e5e5;
}
#walletContainer .gwp-icon-label {
  font-size: 12px;
}
#walletContainer .panel-body {
  padding: 15px !important;
}
@media (max-width: 768px){
  #walletContainer .row {
    margin-left: -20px!important;
  }
  #walletContainer .gwp-information {
    margin-left: -6px!important;
}
#walletContainer .modal-footer {
  padding: 15px 15px 15px 30px !important;
  text-align: left !important;
}
#walletContainer .modal-header {
  padding-left: 22px;
  padding-bottom: 15px;
  padding-top: 10px;
  border-bottom: 1px solid #e5e5e5;
}
#walletContainer .wallet-footer-button {
  margin-top: -10px;
  margin-left: -10px;
}
}
@media (max-width: 430px){
  #walletContainer .row {
    margin-left: -24px!important;
  }
  #walletContainer .gwp-information {
    margin-left: -6px!important;
}
#walletContainer .modal-footer {
  padding: 15px 15px 15px 30px !important;
  /* margin-left: -17px; */
  text-align: left !important;
}
#walletContainer .modal-header {
  padding-left: 14px;
  padding-bottom: 15px;
  padding-top: 10px;
  border-bottom: 1px solid #e5e5e5;
}
#walletContainer .wallet-footer-button {
  margin-top: -10px;
  margin-left: -17px;
}
}

