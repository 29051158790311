.agentInfo>.panel-body{
    padding:15px!important;
}
.agentInfo .dl-horizontal>dt, .agentInfo .dl-horizontal>dd{
    width:auto;
}
.agentInfo .dl-horizontal>dt>title, .agentInfo .dl-horizontal>dd>title{
    width:100px;
}
.agentInfo .dl-horizontal dt{
    color:black;
    margin-right: 60px;
}
.agentInfo .dl-horizontal{
    margin-bottom:0px;
}
.agentInfo .glyphicon-user{
    margin-right: 5px;
}
.agentInfo .gwp-label{
    margin: 0px;
    line-height:24px;
}
.agentInfo .gwp-icon, .agentInfo .gwp-icon-label{
    margin-top:5px;
}
.agentInfo .gwp-btn-container {
    margin-top: -15px;
    margin-left: -10px;
    display: inline-block;
}
.agentInfo .dl-horizontal{
    color:black!important;
}
#allAgentsDialog .dl-horizontal>dt{
    display: inline-block;
    margin-bottom: 2px;
    width: auto;
    color:#337ab7;
    text-align:left;
}
#allAgentsDialog .dl-horizontal>dd{
    margin-left: 60px;
}

#agentInfoPanel .dl-horizontal>dd{
    margin-left: 0px;
}

#agentInfoPanel .splitPerctage {
	margin-left:55px;
}
#agentInfoPanel .viewAllAgent {
	font-weight: 700 !important;
}
#agentInfoPanel .bizTel {
	margin-left:45px;
}
#agentInfoPanel .agentEmail {
	margin-left:50px;
}
#allAgentsDialog .dl-horizontal .agentName{
    color: black;
}

@media (min-width: 768px){
	#allAgentsDialog .dl-horizontal>dd{
		margin-left: 0px;
	}
    #agentInfoPanel .viewAllAgent {
        font-weight: 700 !important;
    }
}
@media (max-width: 768px){
	#allAgentsDialog .dl-horizontal>dt{
        display: block;
        margin-bottom: 2px;
        width: auto;
        color:#337ab7;
        text-align:left;
    }
    #agentInfoPanel .viewAllAgent {
        font-weight: 700 !important;
    }
    #allAgentsDialog .dl-horizontal>dd{
		margin-left: 0px;
	}
}
#allAgentsDialog .gwp-dl > dt > span{
    color: #337ab7;
  }

  @media (max-width: 430px){
    #agentInfoPanel .splitPerctage {
        margin-left:0px;
    }
    
    #agentInfoPanel .bizTel {
        margin-left:0px;
    }
    #agentInfoPanel .agentEmail {
        margin-left:0px;
    }
    #agentInfoPanel .viewAllAgent {
        font-weight: 700 !important;
    }
}