#addnewmember .required {
    color: #000000 !important;
    font-size: 12px !important;
    font-weight: 700;
    margin: 5px 5px 5px 0;
  }
  #addnewmember .required:before {
    font-size: 12px !important;
    content: "*";
    margin-right: 5px;
    color: red;
    font-weight: 700;
  }

  #addnewmember #errorMessageType .gwp-label-required:before {
    font-size: 12px !important;
    content: "*";
    margin-right: 5px;
    margin-left: 14px;
    color: red;
    font-weight: 700;
  }

  #addnewmember .gwp-btn-container {
    margin-top: 0px;
    display: inline-block;
  }

  #addnewmember .nextButton {
    margin-top: 28px;
  }

  #addnewmember .table-row {
    line-height: 80%;
  }