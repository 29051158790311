#bulkRegistration .bulk-subtitle {
  padding: 10px 0 6px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #555;
}

#bulkRegistration .gwp-dl > dt {
  margin-bottom: 0px !important;
  text-align: left;
}
#bulkRegistration .gwp-dl > dd {
  width: 80px;
}
@media(min-width:768px){
  #bulkRegistration .gwp-dl > dd {
    margin-left: 80px;
  }
  #bulkRegistration .gwp-dl > dt {
    width: 80px;
  }
}
#bulkRegistration .bulk-subtitle-padding {
  padding: 20px 0 10px 0;
}

#bulkRegistration input[type="radio"].gwp-input {
  margin-top: 5px;
}
#bulkRegistration .reg-blue,
.reg-red,
.reg-green {
  float: right;
}
#bulkRegistration .reg-blue {
  color: blue;
}
#bulkRegistration .reg-red {
  color: red;
}
#bulkRegistration .reg-green {
  color: green;
}
#bulkRegistration .gwp-link-btn {
  text-decoration-line: underline;
  padding: 0;
}
#bulkRegistration .bulk-control {
  margin: 0 0 0 14px;
}
