.hippa-access-authorization {}

.mtb-15 {
    margin: 15px 0px;
}

.hippa-access-authorization table {
    max-width: 350px;
}

.hippa-access-authorization table td,
th {
    padding: 10px 5px;
}

.hippa-access-authorization label {
    font-weight: 100;
    margin-left: 5px;
    margin-top: 8px;
}

.group {
    display: flex;
    align-items: center;
    justify-content: center;
}